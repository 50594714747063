/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@use "./variables" as *;

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: $gray;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: $gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($black, 30%);
  border-radius: 10px;
}
body {
  background: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
* {
  font-family: "Poppins", sans-serif;
  font-size: inherit;
  box-sizing: border-box;
  &:focus,
  &:hover {
    outline: none;
  }
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
input::-ms-reveal, input::-ms-clear {
  display: none;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
img {
  max-width: 100%;
}
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  cursor: pointer;
  border: 0;
  outline: 0;
  background: 0 0;
  padding: 0;
}
.mb_0 {
  margin-bottom: 0 !important;
}
.mt_0 {
  margin-top: 0 !important;
}
.ml_0 {
  margin-left: 0 !important;
}
.mr_0 {
  margin-right: 0 !important;
}
.m_0 {
  margin: 0 !important;
}
.mb_8 {
  margin-bottom: 0.5rem;
}
.p_0 {
  padding: 0 !important;
}
.pl_0 {
  padding-left: 0 !important;
}
.pt_0 {
  padding-top: 0 !important;
}
.pb_0 {
  padding-top: 0 !important;
}
.pt_16 {
  padding-top: 1rem !important;
}
.pl_18 {
  padding-left: 1.125rem !important;
}
.mt_8 {
  margin-top: 0.5rem;
}
.mt_12 {
  margin-top: 0.75rem;
}
.border_0 {
  border: 0 !important;
}
.textCenter {
  text-align: center !important;
}
.orderTextarea {
  min-height: 100px !important;
}

