// Variable
@use "../_index" as *;

.layoutContainer {
  display: block;
  width: 100%;
  height: 100dvh;
  @media screen and (min-width: $lg) {
    display: flex;
  }
}

/* Layout Sidemenu Start */
.layoutSidemenu {
  background: $white;
  flex: 0 0 270px;
  width: 270px;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid $lightgray;
  transition: all 0.3s ease;
  @media screen and (max-width: $lg) {
    position: fixed;
    flex: 0 0 300px;
    width: 300px;
    left: -300px;
    z-index: 99;
  }
  &__logo {
    width: 100%;
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid $lightgray;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    cursor: pointer;
    position: sticky;
    top: 0;
    background-color: $white;
    overflow: hidden;

    svg,
    img {
      width: 135px;
      height: auto;
      max-height: 36px;
      object-fit: contain;
    }
  }

  &__fullHeight {
    height: calc(100dvh - 130px);
    overflow-y: auto;
  }

  &__list {
    &--item {
      &:hover {
        span {
          color: $primaryBlack;
          transition: all 0.3s ease;
          &.icon {
            background: $primary;
            svg {
              path {
                stroke: $white;
                transition: all 0.3s ease;
              }
            }
            &--user {
              svg {
                path {
                  stroke: none;
                  fill: $white;
                  transition: all 0.3s ease;
                }
              }
            }
          }
        }

        .version {
          color: $primaryGray;
        }
      }
    }

    &--link {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding: 0.625rem 1rem;
      text-decoration: none !important;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;

        path {
          transition: all 0.3s ease;
        }
      }

      span {
        font-size: 0.875rem;
        font-weight: 400;
        color: $secondary;
        line-height: 20px;
        transition: all 0.3s ease;

        &.icon {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 32px;
          width: 32px;
          height: 32px;
          border-radius: 50px;
        }
      }
      .arrowDown {
        position: absolute;
        right: 12px;
        top: 14px;
        z-index: 1;
        transform: rotate(0deg);
        transition: transform 1s linear;
        svg {
          width: 10px;
          height: 6px;
        }
      }
      &.open {
        .arrowDown {
          top: 6px;
          transform: rotate(180deg);
        }
      }
    }
    &--submenu {
      position: relative;
      display: none;
      &.open {
        display: block;
      }
      &::after {
        content: "";
        background: $primaryGray;
        position: absolute;
        top: -10px;
        left: 30px;
        width: 1.5px;
        height: 100%;
        z-index: 1;
      }
      .layoutSidemenu__list--item {
        margin-bottom: 0;
        .layoutSidemenu__list--link {
          padding-left: 65px;
          padding-right: 5px;
          padding-top: 0;
          position: relative;
          gap: 6px;
          svg {
            path {
              stroke: none;
            }
          }
          span {
            font-size: 0.75rem;
            color: $primaryGray;
          }
        }
      }
      .layoutSidemenu__selected {
        .layoutSidemenu__list--link {
          svg {
            path {
              fill: $primary;
              stroke: none;
            }
          }

          span {
            color: $primary;
          }
          &::after {
            content: "";
            background: $primary;
            position: absolute;
            top: -10px;
            left: 30px;
            width: 1.5px;
            height: 100%;
            z-index: 11;
          }
        }
      }
    }
  }
  &__selected {
    .layoutSidemenu__list--link {
      background: rgba($primary, 7%);
      span {
        font-weight: 500;
        color: $primary;
        &.icon {
          background: $primary;
          svg {
            path {
              stroke: $white;
              transition: all 0.3s ease;
            }
          }
          &--user {
            svg {
              path {
                stroke: none;
                fill: $white;
                transition: all 0.3s ease;
              }
            }
          }
          &--user {
            svg {
              g {
                rect {
                  stroke: none;
                  fill: $white;
                  transition: all 0.3s ease;
                }
              }
            }
          }
        }
      }
    }
  }

  &__version {
    background-color: $white;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $lightgray;
    position: sticky;
    bottom: 0;
    height: 60px;
    &__title {
      display: block;
      font-size: 0.875rem;
      color: $primaryGray;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }
}

/* Layout layoutGrid Start */
.layoutGrid {
  width: 100%;
  @media screen and (min-width: $lg) {
    width: calc(100% - 270px);
  }
}
.sidebar-hidden {
  .layoutSidemenu {
    left: 0;
  }
}
/* Table Layout Start */
.tableWrap {
  background: $white;
  padding: 1.25rem;
  @media screen and (max-width: $md) {
    padding: 1rem;
  }
  .filterLeft {
    position: relative;
    &__dropdown {
      display: block;
      position: absolute;
      width: 400px;
      top: 100%;
      right: 0;
      box-shadow: 0px 0px 56.21px 0px #0000001f;
      z-index: 1;
      margin-top: 40px;
      height: calc(100dvh - 235px);
      overflow: auto;
      .heading{
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color:#4B4B4B;        

      }
      .item{
        display: flex;
        font-size: 1rem;
        align-items: center;
        gap: 6px;
        .filterbutton{
          display: flex;
          align-items: center;
        }
        .label-text{
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 24.45px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #4D5761;
        }
      }
      &::after {
        display: none;
      }
      &__detail {
        background: $white;
        border-radius: 12px;
      }
      &__body {
        display: flex;
        flex-direction: column;
        background: $white;
        padding: 1.5rem 1.875rem;
        gap: 16px;
        .form__label {
          margin-bottom: 0.5rem;
        }
      }
      &__footer {
        background: rgba($lightgray, 30%);
        padding: 1.5rem 1.875rem;
      }
    }
    &--open {
      .filter__dropdown {
        display: block;
        height: calc(100dvh - 235px);
        overflow: auto;
      }
    }
  }
  &--filterOpen {
    .tableContainer {
      @media screen and (min-width: $xl) {
        width: calc(100% - 420px);
      }
    }
    .filter {
      position: static;
      &__dropdown {
        left: auto;
        right: 0;
        margin-top: 2px;
        &::after {
          display: none;
        }
      }
    }
  }
  &--formInner {
    padding: 40px 0 25px;
    .tableContainer {
      height: auto !important;
      .table {
        thead {
          tr {
            background-color: #ebeeef;
          }
        }
        tbody {
          tr {
            background-color: rgba(#6b6b6b, 4%) !important;
          }
        }
      }
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    border-bottom: 1px solid $borderLight;
    padding-bottom: 1.25rem;
    position: relative;
    @media screen and (max-width: $md) {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
    @media screen and (max-width: $sm) {
      gap: 8px;
    }

    &--title {
      color: $primaryBlack;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      flex: 1;

      @media screen and (min-width: $xxxl) {
        font-size: 1.5rem;
        gap: 16px;
      }

      span {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    &--actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      @media screen and (max-width: $sm) {
        gap: 10px;
      }
    }
    .formRow {
      margin-bottom: 0;
      gap: 16px;
      .formCol {
        &--flex {
          display: flex;
          align-items: center;
          gap: 16px;
        }
        &--search {
          width: 100%;
        }
      }
    }
  }
  .tableContainer {
    height: calc(100dvh - 235px);
    box-shadow: none;
    position: relative;
    overflow-x: auto;
    .table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      thead {
        display: table-header-group;
      }
      tbody {
        display: table-row-group;
      }
      tr {
        color: inherit;
        display: table-row;
        vertical-align: middle;
        outline: 0;
        border-bottom: 1px solid $borderLight;
        height: 60px;
        .form__checkbox {
          &__option {
            margin: 0;
          }
        }
      }
      th,
      td {
        padding: 0.625rem;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 20px;
        position: relative;
        text-align: left;
        vertical-align: middle;
        max-width: 180px;
        min-width: 180px;
        word-break: break-word;
        .sortingDiv {
          display: flex;
          gap: 5px;
          align-items: center;
          &__icon {
            display: flex;
            align-items: center;
            position: relative;
            svg {
              width: 11px;
              height: 8px;
            }
          }
          &--center {
            justify-content: center;
          }
          &--Rowthree {
            justify-content: space-between;
            text-align: center;
            .item {
              width: calc(100% / 3);
              .form__input {
                min-width: 30px;
                text-align: center;
              }
            }
          }
        }

        @media screen and (max-width: $sm) {
          white-space: nowrap;
        }

        @media screen and (min-width: $xxxl) {
          padding: 0.625rem 0.375rem;
        }

        &:first-child {
          padding-left: 1rem;
          max-width: 60px;
          min-width: 60px;
          .formField__checkbox {
            label {
              margin-right: 0;
            }
          }

          @media screen and (min-width: $xxxl) {
            padding-left: 1.5rem;
          }
        }

        &:last-child {
          padding-right: 1rem;
          max-width: auto;
          min-width: 160px;
          @media screen and (min-width: $xxxl) {
            padding-right: 1.5rem;
          }
        }
      }

      th {
        color: $darkGray;
      }

      td {
        color: $primaryBlack;
      }
      tbody {
        tr {
          &:hover {
            .profile__initials {
              background-color: $primary;
              color: $white;
              transition: all 0.3s ease;
            }
          }
          &:nth-child(odd) {
            background-color: rgba($primary, 7%);
          }
        }
      }

      .cellText {
        position: relative;
        &__subText {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.625rem;
          font-weight: 400;
          position: absolute;
          left: -5px;
          width: 100%;
          bottom: -18px;
        }
        &--center {
          text-align: center;
        }
        span {
          display: block;
        }
        .form__input {
          background: transparent;
          padding: 0.5rem;
          font-size: 0.875rem;
          font-weight: 400;
          color: $primaryBlack;
          width: 0;
          min-width: 90px;
          height: 36px;
          border: none;
          border-radius: 0;
          text-transform: capitalize;
          &--active {
            background: $white;
          }
        }
        &__dark,
        &__title {
          color: $primaryBlack;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 20px;
          margin: 0 0 4px 0;
          transition: all 0.3s ease;
          text-transform: capitalize;
          &.cell--link {
            transition: all 0.3s ease;
            text-decoration: underline;
            text-decoration-color: $white;
            cursor: pointer;

            &:hover {
              color: $primary;
              text-decoration-color: $primary;
              transition: all 0.3s ease;
            }
          }
          &--gray {
            color: $darkGray;
            font-weight: 500;
          }
          &--flex {
            display: flex !important;
            align-items: center;
            gap: 12px;
            button {
              padding: 0;
              background: none;
              border: none;
              height: 20px;
              cursor: pointer;
            }
          }
        }
        &__primary {
          text-transform: capitalize;
          color: $primary;

          &--icon {
            display: flex !important;
            align-items: center;
            text-decoration: underline;
            gap: 5px;
          }
          &--none {
            display: flex !important;
            align-items: center;
            justify-content: center;
          }
        }
        &__light {
          color: $primaryGray;
          font-size: 0.75rem;
          font-weight: 500;
          line-height: 18px;
        }
        &__colorPicker {
          background: $white;
          width: 80px;
          height: 20px;
          border-radius: .25rem;
          border:1px solid $border;
          margin:0 auto;
          cursor: pointer;
        }
      }

      .profile {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        &__image {
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        &__initials {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          flex: 0 0 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          color: $primary;
          border: 1px solid $primary;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 20px;
          transition: all 0.3s ease;
          overflow: hidden;
        }
        &__name {
          display: flex;
          align-items: center;
          gap: 5px;
          color: $primary;
          text-transform: capitalize;
          &--underline {
            text-decoration: underline;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        &.flex--end {
          justify-content: flex-end;
        }
        &.flex--center {
          justify-content: center;
          &.flex--expired {
            color: #f04438
          }
          &.flex--valid {
            color: rgb(22, 203, 121);
          }
        }
        .btn__active {
          svg {
            path {
              stroke: $primary;
            }
          }
        }

        .btn__white {
          min-width: 80px;

          &.w_fitContent {
            min-width: auto;
          }

          &:hover {
            border-color: $primary;
            color: $primary;
          }
        }

        .btn {
          height: 32px;

          &--sm {
            min-width: 32px;
            width: 32px;
            flex: 0 0 32px;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }

        .btnHover {
          svg {
            path {
              transition: 0.3s ease;
            }
          }

          &:hover {
            svg {
              path {
                stroke: $primary;
                transition: 0.3s ease;
              }
            }
          }
        }
        .dropdown {
          &__item {
            position: relative;
            text-align: center;
            .dropdownMenu {
              display: none;
              background: $white;
              position: absolute;
              width: 180px;
              right: 0;
              top: 100%;
              box-shadow: 0px 0px 14.5px 0px #00000026;
              z-index: 1;
              &--open {
                display: block;
              }
              .dropdown__item {
                background: $white;
                padding: 0.7rem 1rem;
                display: flex;
                align-items: center;
                gap: 12px;
                font-size: 0.875rem;
                font-weight: 400;
                color: $primaryBlack;
                text-align: left;
                cursor: pointer;
                &:hover,
                &--active {
                  background: rgba($primaryBlack, 6%);
                }
              }
            }
          }
        }
      }
      &.orderTable{
        tr{
          height: 40px;
          th, td {
            height: 40px;
          }
        }
      }
    }
  }
  &--dispatchBoard {
    .tableContainer {
      .table {
        th,
        td {
          min-width: 205px;
          &:first-child {
            min-width: 70px;
          }
          &:last-child {
            min-width: 200px;
          }
        }
      }
    }
  }
  &--order {
    margin: 0 -1.25rem !important;
    @media screen and (max-width: $md) {
      margin: 0 -1rem !important;
    }
    .tableContainer {
      height: auto;
      .table {
        thead {
          tr {
            background-color: #ebeeef;
          }
        }
        tbody {
          tr {
            background-color: rgba(#6b6b6b, 4%) !important;
          }
        }
        tr {
          border-bottom: none;
        }
        .cellText {
          .form {
            &__input {
              min-width: 70px;
            }
          }
        }
      }
    }
  }
}

/* Table Layout End */

/* Page Layout */
.page {
  &__head {
    padding: 1rem 1.25rem;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    border-bottom: 1px solid $lightgray;

    @media screen and (max-width: $md) {
      padding: 1rem;
    }
    &--title {
      color: $primaryBlack;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 32px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      @media screen and (max-width: $sm) {
        font-size: 1rem;
        line-height: 24px;
        gap: 8px;
      }

      @media screen and (min-width: $xxxl) {
        font-size: 1.5rem;
      }
      &.gap16 {
        gap: 16px;
        @media screen and (max-width: $sm) {
          gap: 8px;
        }
        .backBtn {
          background: none;
          display: flex;
          align-items: center;
          flex: 0 0 28px;
          width: 28px;
          height: auto;
          border: none;
          cursor: pointer;
        }
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      position: relative;
      @media screen and (max-width: $sm) {
        gap: 8px;
      }
      .userProfile {
        position: relative;
        &__link {
          display: flex;
          align-items: center;
          padding-right: 1.5rem;
          position: relative;
          font-size: 0.875rem;
          font-weight: 500;
          color: $primaryBlack;
          gap: 12px;
          text-decoration: none;
          cursor: pointer;
          &::after {
            content: "";
            background: url("../../svg/arrow-down-iocn.svg") no-repeat;
            background-position: center center;
            position: absolute;
            width: 18px;
            height: 8px;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 1;
          }
          .picIcon {
            background: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            font-size: 0.875rem;
            font-weight: 700;
            color: $white;
            text-transform: uppercase;
            border-radius: 50px;
            &__img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50px;
            }
          }
        }
        &__dropdown {
          display: none;
          background: $white;
          padding: 1.5rem;
          position: absolute;
          width: 360px;
          right: 0;
          top: 100%;
          box-shadow: 0px 5px 11.9px 0px #0f0f0f33;
          border-radius: 10px;
          overflow: hidden;
          z-index: 100;
          &::after {
            content: "";
            background: linear-gradient(
              90deg,
              #26617d -160.3%,
              rgba(38, 97, 125, 0.6) 100%
            );
            position: absolute;
            width: 100%;
            height: 78px;
            left: 0;
            right: 0;
            top: 0;
          }
          &--open {
            display: block;
          }
          @media screen and (max-width: $sm) {
            padding: 1rem;
          }
          .userdetials {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            text-align: center;
            width: 100%;

            &__info {
              font-size: 0.875rem;
              font-weight: 400;
              color: $primaryGray;
              .pic {
                background: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                font-size: 1.625rem;
                font-weight: 700;
                color: $white;
                text-transform: uppercase;
                border-radius: 50px;
                box-shadow: 0px 1px 4.4px 0px #00000040;
                margin: 0 auto 0.875rem;
                position: relative;
                z-index: 1;
                &__img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50px;
                }
              }
              .name {
                font-size: 1.5rem;
                font-weight: 500;
                color: $black;
                margin-bottom: 0.25rem;
                line-break: anywhere;
              }
              .role {
                text-transform: capitalize;
                font-size: 0.875rem;
                font-weight: 400;
                color: $black;
              }
              .para {
                font-size: 0.875rem;
                font-weight: 400;
                color: $primaryGray;
              }
            }
            .btn__group {
              width: 100%;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: 0;
              margin: 0 -8px;
              &__item {
                width: 50%;
                padding: 0 8px;
                &:nth-child(3) {
                  width: 100%;
                  margin-top: 1rem;
                }
              }
              .btn {
                width: 100%;
                font-weight: 400;
                border-radius: 6px;
              }
            }
          }
        }
      }
      .btnCollapse {
        display: none;
        align-items: center;
        @media screen and (max-width: $lg) {
          display: flex;
        }
      }
    }
  }
  &__body {
    height: calc(100dvh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1.5rem;
    background: $white;

    @media screen and (max-width: $md) {
      padding: 1rem;
    }
    .details {
      padding: 1.25rem;
      @media screen and (max-width: $md) {
        padding: 1rem;
      }
      .form {
        &__label {
          font-size: 0.875rem;
        }
        &__input {
          &--sm {
            font-size: 0.875rem;
          }
        }
        &__checkbox {
          &__label {
            font-size: 0.875rem;
          }
        }
        &__radio {
          &__label {
            font-size: 0.875rem;
          }
        }
      }
      &--agentRates {
        .form__section {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .tableWrap {
        margin-top: 30px;
        .tableContainer {
          height: auto;
        }
      }
      &--agentHubZip {
        .tableWrap {
          margin: 0;
          padding: 0 0 1.5rem;
          .tableContainer {
            .table {
              thead {
                tr {
                  background: #ebeeef;
                  border-bottom: none;
                }
              }
              tbody {
                tr {
                  background: rgba($primary, 5%) !important;
                  border-bottom: none;
                }
              }
              tr {
                height: 45px;
                th,
                td {
                  &:first-child {
                    width: 60px;
                    max-width: auto;
                  }
                  &:last-child {
                    width: 80px;
                  }
                }
              }
              .actions {
                .btn {
                  height: auto;
                }
              }
            }
            .pagination {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              background: rgba($primary, 5%);
              min-height: 45px;
            }
          }
        }
      }
    }
    .filterWrap {
      background: $white;
      padding: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      .flexRow {
        display: flex;
        align-items: center;
        gap: 16px;
        .formCol {
          &--search {
            width: 420px;
            .form__input--search {
              height: 46px;
            }
          }
          .btn {
            border-color: rgba($border, 36%);
            color: $gray;
            min-width: 200px;
            height: 46px;
            gap: 16px;
          }
        }
        .form__checkbox {
          &__option {
            margin-bottom: 0;
          }
        }
      }
    }
    .accordionAgentList {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 1rem;
      &__item {
        padding: 1.5rem 1.25rem;
        background-color: #ffffff;
        box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1019607843);
        position: relative;
        &.active {
          .accordionHeading {
            &::after {
              display: none;
            }
            .title {
              span {
                font-weight: 400;
              }
            }
            .icon {
              padding-left: 0;
              padding-right: 15px;
              transform: rotate(180deg);
              transition: transform 1s linear 0s;
              svg {
                path {
                  stroke: $black;
                }
              }
            }
          }
          .accordionData {
            display: block;
          }
        }
      }
      .accordionHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        font-weight: 1.125rem;
        font-weight: 700;
        color: $primaryBlack;
        min-height: 25px;
        margin-bottom: 0;
        position: relative;
        &::after {
          content: "";
          background: #d9d9d9;
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          right: 0;
          bottom: 12px;
        }
        .title {
          background: #fff;
          padding-right: 15px;
          position: relative;
          z-index: 1;
          span {
            font-weight: 700;
          }
        }
        .icon {
          background: #fff;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          padding-left: 15px;
          z-index: 1;
          transform: rotate(0deg);
          transition: transform 1s linear 0s;
        }
      }
      .accordionData {
        display: none;
      }
      .tableWrap {
        padding: 0;
        margin-top: 20px;
        .tableContainer {
          .table {
            thead {
              tr {
                background: $white;
              }
            }
            tbody {
              tr {
                background: $white;
                &:nth-child(even) {
                  background: rgba($primary, 5%);
                }
                &.acordionRow {
                  display: none;
                  background: $white;
                  &.active {
                    display: table-row;
                  }
                }
              }
            }
            th,
            td {
              &:first-child {
                max-width: 125px;
              }
              .icon {
                transform: rotate(0deg);
                transition: transform 1s linear 0s;
                &.active {
                  transform: rotate(90deg);
                  transition: transform 1s linear 0s;
                }
              }
            }
            td {
              .tableWrap {
                margin-top: 0;
                padding: 15px 30px 20px;
                .tableContainer {
                  border: 1px solid $primary;
                  border-radius: 12px;
                  .table {
                    thead {
                      tr {
                        background: rgba($primary, 13%);
                      }
                    }
                    tbody {
                      background: rgba($primary, 5%);
                      tr {
                        border-bottom: none;
                        &:nth-child(odd),
                        &:nth-child(even) {
                          background: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .pagination {
          padding-bottom: 0;
          min-height: 1px;
          position: static;
          bottom: auto;
        }
      }
    }
    .orderPriceDetail {
      padding: 1.25rem;
      border: 1px solid #8c8c8c;
      border-radius: 0.5rem;
      .title {
        font-size: 1rem;
        font-weight: 600;
        color: $primaryBlack;
        margin-bottom: 1rem;
      }
      &__history {
        width: 100%;
        border: 1px solid $lightgray;
        border-radius: 6px;
        &__header {
          padding: 1rem;
          display: flex;
          align-items: center;
          background: #ebeeef;
          justify-content: space-between;
          gap: 10px;
          .item {
            font-size: 0.875rem;
            font-weight: 700;
            color: $darkGray;
          }
        }
        &__body {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 12px;
          padding: 1rem;
          .item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            font-size: 0.875rem;
            font-weight: 500;
            color: $darkGray;
          }
        }
        &__footer {
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid $lightgray;
          gap: 10px;
          .item {
            font-size: 0.875rem;
            font-weight: 700;
            color: $darkGray;
          }
        }
      }
    }
  }

  &__full {
    .page__body {
      padding: 0;
      background: rgba($primary, 8%);
      &--agentRates {
        height: calc(100dvh - 130px);
      }
    }
  }

  &__fullBody {
    .page__body {
      height: calc(100dvh - 130px);
      padding: 0;
    }
  }
}
.tableRowError {
  background: #fef3f2;
}
.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primaryBlack;
  font-size: 1rem;
  font-weight: 600;
  line-height: 28px;
  &.floating {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    &.tableFloating {
      top: 50px;
    }
  }
}
.email-chips {
  border: 1px solid rgba(102, 102, 102, 0.35);
  border-radius: 0.375rem;
  background-color: #fff;
  padding: 5px;
  .chips-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: none;
  }
  input {
    border: none;
    width: auto;
    background: none;
  }

  .chip {
    display: flex;
    align-items: center;
    background-color: #26617d14;
    border-radius: 16px;
    padding: 4px 8px;
    margin: 4px;
    font-size: 12px;
  }

  .chip__close {
    background: #00000042;
    border: none;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    color: $white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
  }
}
.email-chips--disabled {
  background: rgba(210, 214, 219, 0.5019607843);
  border: 1px solid rgba(210, 214, 219, 0.5019607843);
  cursor: not-allowed;
}
.browsefile__container {
  &__files {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
/* Page Order History */
.addFinalMileWrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $lightgray;
    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }
    .addItemCard {
      background: $white;
      border: 1px solid $lightgray;
      border-radius: .25rem;
      //overflow: hidden;
      &__head {
        background: #FAFAFA;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:.5rem 1.25rem;
        gap: 10px;
        border-bottom: 1px solid $lightgray;
        .title {
          font-size: 1rem;
          font-weight: 700;
          color: $darkGray;
          margin-bottom: 0;
        }
        .btn__group {
          gap: 12px;
          margin-bottom: 0 !important;
          .btn {
            padding: .25rem .5rem;
            line-height: 18px;
            min-height: 30px;
            &__danger {
              background: $danger;
            }
          }
        }
      }
      &__body {
        padding:1.25rem 1.25rem 1.5rem;
        .formRow {
          gap: 16px;
          margin-bottom: 1rem;
          &:last-of-type {
            margin-bottom: 0;
          }
          &--flex {
            display: flex;
            align-items: center;
            gap: 6px !important;
            .form__input {
              padding-left: .4rem;
              padding-right: .4rem;
            }
          }
          &--flexOneThird {
            display: flex;
            flex-direction: column;
            @media screen and (min-width:$lg) {
              flex-direction: row;
            }
            .formCol {
              width: 100%;
              @media screen and (min-width:$lg) {
                width: 33.333%;
              }
              &:last-child {
                width: 100%;
                @media screen and (min-width:$lg) {
                  width: calc(100% - 33.333%);
                }
              }
            }
          }
          &__2 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
       
            @media screen and (min-width: $xxl) {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }
          }
        }
      }
      &--package {
        background: #6B6B6B0A;
        border-color: #6B6B6B0A;
        .addItemCard__head {
          background: $primary;
          border-color: $primary;
          .title {
            color: $white;
          }
        }
      }
    }
  }
}
.orderHistoryDetail {
  padding: 0 1.25rem 1.25rem 1.25rem;
  @media screen and (max-width: $md) {
    padding: 0 1rem 1rem;
  }
}
.orderHistory {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  &__row {
    position: relative;
    background: $white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
    border: 1px solid $lightgray;
    border-radius: 8px;
    @media screen and (min-width: $sm) {
      flex-direction: row;
      gap: 20px;
    }
    &__col {
      width: calc(100% / 7);
      &:last-child {
        width: 130px;
        display: flex;
        justify-content: flex-end;
      }
      .cellText {
        span {
          display: block;
        }
        &__title {
          font-size: 0.875rem;
          font-weight: 600;
          color: $primaryBlack;
          line-height: 20px;
          margin: 0 0 4px 0;
          transition: all 0.3s ease;
          &.cell--link {
            transition: all 0.3s ease;
            text-decoration: underline;
            text-decoration-color: $white;
            cursor: pointer;

            &:hover {
              color: $primary;
              text-decoration-color: $primary;
              transition: all 0.3s ease;
            }
          }
        }
        &__text {
          font-size: 0.75rem;
          font-weight: 400;
          color: $gray;
          line-height: 20px;
          transition: all 0.3s ease;
          &--link {
            color: $primary;
          }
        }
        .link {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 0.875rem;
          text-decoration: none;
        }
      }
    }
    &--orderDetail {
      justify-content: space-between;
      background: rgba($lightgray, 16%);
      border-color: rgba($lightgray, 16%);
    }
  }
}
.orderDetail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: $primaryBlack;
      line-height: 20px;
      margin: 0 0 4px 0;
      transition: all 0.3s ease;
      span {
        display: block;
        font-size: 0.75rem;
        font-weight: 400;
        color: $primary;
        line-height: 20px;
      }
    }
  }
  .orderDetailCard {
    background: $white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.25rem;
    border: 1px solid $lightgray;
    border-radius: 8px;
    @media screen and (max-width: $md) {
      padding: 1rem;
    }
  }
}
/* End Page Order History */
.emailNotificationList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  height: 100%;
  &__item {
    width: calc(100% / 2);
    padding: 16px;
  }
  &__card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: $white;
    padding: 1.5rem;
    box-shadow: 0px 1px 3px 1px #00000026;
    height: 100%;
    .title {
      font-size: 1.375rem;
      font-weight: 600;
      color: $primaryBlack;
      margin-bottom: 0;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
    }
  }
  &__info {
    font-size: 0.75rem;
    font-weight: 400;
    color: rgba($primaryBlack, 50%);
    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      color: $primaryBlack;
      margin-bottom: 0.25rem;
    }
    p {
      font-size: 0.75rem;
      font-weight: 400;
      color: rgba($primaryBlack, 50%);
      margin-bottom: 0;
    }
  }
  .form__switchBtn {
    &__option {
      .switchBtn {
        .toggle {
          & + label {
            font-size: 0.75rem;
            font-weight: 500;
            height: 24px;
            line-height: 24px;
            padding: 0px 14px;
            &:before {
              width: 20px;
              height: 20px;
            }
            .off {
              margin-left: 16px;
            }
          }
          &:checked + label {
            .on {
              margin-right: 16px;
            }
            &:before {
              margin-left: -24px;
            }
          }
        }
      }
    }
  } 
}
.dashboardWrap {
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  gap: 15px;
  @media screen and (min-width: $lg) {
    gap: 36px;
  }
  &__head {
    .formFields {
      display: flex;
      align-items: center;
      gap: 16px;
      .form__Field {
        display: flex;
        align-items: center;
        gap: 10px;
        .form__label {
          font-size: .875rem;
          color: $black;
          font-weight: 500;
          margin-bottom: 0;
        }
        .form__select,
        .form__date,
        .form__input {
          height: 42px;
          @media screen and (min-width: $sm) {
            width: 160px;
          }
        }
        .form__date {
          @media screen and (min-width: $sm) {
            width: 185px;
          }
        }
      }
      .btn__group {
        .btn {
          font-size: .875rem;
          font-weight: 500;
          border-radius: 0.625rem;
          min-width: 120px;
        }
      }
    }
  }
  .card {
    background:$white;
    padding: 1.25rem;
    border: 1px solid #EFF0F6;
    box-shadow: 0px 5px 20px 0px #0000000D;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    &--transparent {
      background: none;
      padding: 0;
      border: none;
      box-shadow: none;
      border-radius: none;
      .dashboardWrap__head {
        flex-direction: column;
        gap: 20px;
        &__3 {
          .dashboardWrap__head__item {
            width: 100%;
          }
        }
        .card {
          &--sad {
            border-left: none;
          }
        }
      }
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 1rem;
      @media screen and (min-width: $sm) {
        flex-wrap: nowrap;
      }
      .formFields {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .form__Field {
        display: flex;
        align-items: center;
        gap: 10px;
        .form__label {
          font-size: .875rem;
          color: $black;
          font-weight: 500;
          margin-bottom: 0;
        }
        .form__select,
        .form__date,
        .form__input {
          padding-top: .25rem;
          padding-bottom: .25rem;
          height: 32px;
          @media screen and (min-width: $sm) {
            width: 160px;
          }
        }
        .form__date {
          @media screen and (min-width: $sm) {
            width: 160px;
          }
        }
      }
      .title {
        margin-bottom: 0 !important;
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.5rem;
      color: $primaryBlack;
      gap: 10px;
      margin-bottom: 1rem;
    }
    &__body {
      canvas {
        object-fit: contain;
        width: 100% !important;
        height: 380px !important;
      }
      .tableWrap {
        padding: 0;
        margin: 0 -1.25rem;
        .tableContainer {
          height: auto;
          .table {
            thead {
              background: none;
              border: none;
              border-bottom:1px solid #D5D5D5;
            }
            tbody {
              tr {
                background: none;
                border-bottom: none;
                height: 40px;
                &:first-child {
                  td {
                    padding-top: 1.25rem;
                  }
                }
                &:last-child {
                  td {
                    padding-bottom: 1.25rem;
                  }
                }
              }
            }
            th {
              font-size: .875rem;
              font-weight: 500;
              color: $primaryBlack;
              max-width: 100%;
              min-width: 170px;
            }
            td {
              font-size: .75rem;
              font-weight: 400;
              max-width: 100%;
              min-width: 170px;
              .cellText {
                display: flex;
                align-items: center;
                gap: 6px;
                &--primary {
                  font-weight: 600;
                  color: $primary;
                }
                &--status {
                  color: #2B3674;
                  font-weight: 500;
                }
                .icon {
                  display: flex;
                  align-items: center;
                  width: 18px;
                  svg {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
              .btn__group {
                .btn {
                  padding: .25rem .625rem;
                  font-size: .75rem;
                  font-weight: 400;
                  color: $primaryBlack;
                  line-height: 20px;
                  border-radius: .5rem;
                  min-height: 30px;
                  &__bg1 {
                    background-color: #FFAAC1;
                    border-color: #FFAAC1;
                  }
                  &__bg2 {
                    background-color: #FFA8A8;
                    border-color: #FFA8A8;
                  }
                  &__bg3 {
                    background-color: #97E1FF;
                    border-color: #97E1FF;
                  }
                  &__bg4 {
                    background-color: #AAFFF9;
                    border-color: #AAFFF9;
                  }
                  &__bg5 {
                    background-color: #B6AAFF;
                    border-color: #B6AAFF;
                  }
                  &__bg6 {
                    background-color: #FFD0AA;
                    border-color: #FFD0AA;
                  }
                }
              }
            }
          }
        }
        .pagination {
          padding-bottom: 0;
          min-height: 1px;
          position: static;
        }
      }
    }
    .commonList {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4px;
      &__item {
        padding:.5rem .25rem 0;
        width: calc(100% / 1);
        @media screen and (min-width:$md) {
          width: calc(100% / 3);
        }
        @media screen and (min-width:$xxl) {
          width: calc(100% / 6);
        }
        .card {
          .title {
            position: relative;
            justify-content: flex-start;
            font-size: 1rem;
            font-weight: 500;
            color: rgba($black, 70%);
            margin-bottom: .5rem;
            &::before {
              content: "";
              background: $success;
              flex: 0 0 10px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
            &--inTransit {
              &::before {
                background: #1BB9F8;
              }
            }
            &--received {
              &::before {
                background: #26617D;
              }
            }
            &--scheduled {
              &::before {
                background: #E9AB6C;
              }
            }
            &--delivered {
              &::before {
                background: #F81BF8;
              }
            }
            &--inTransitWGL {
              &::before {
                background: #1B59F8;
              }
            }
          }
          .value {
            font-size: 2rem;
            font-weight: 700;
            color: $black;
          }
          .picture {
            display: block;
            width: 100%;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .cardRow {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media screen and (min-width: $lg) {
      gap: 20px;
    }
    .col {
      width: 100%;
    }
    &__two {
      flex-direction: column;
      justify-content: space-between;
      @media screen and (min-width: $lg) {
        flex-direction: row;
      }
      .col {
        width: 100%;
        @media screen and (min-width: $lg) {
          width: calc(100% / 2);
        }
      }
    }
     &__oneThird {
      flex-direction: column;
      justify-content: space-between;
      @media screen and (min-width: $lg) {
        flex-direction: row;
      }
      .col {
        width: 100%;
        @media screen and (min-width: $xl) {
          width: 30%;
        }
        &:last-child {
          width: 100%;
          @media screen and (min-width: $xl) {
            width: 70%;
          }
        }
      }
    }
  }
}

.btn__right-align {
  margin-left: auto;
}

.btn__small {
  padding: 0.45rem 0.625rem;
  height: 25px;
  font-weight: 600;
}
